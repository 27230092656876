<template>
  <el-main>
    <page-title>
      <template slot="btn-inner">
        <debounce-button v-loading="loading.submit" type="primary" @click="dataProcessing">
          保存
        </debounce-button>
        <el-button @click="$router.push({ name: 'account'})">
          <i class="iconfont icon-fanhui" />返回
        </el-button>
      </template>
    </page-title>
    <div v-loading="loading.detail" class="partition-area">
      <el-form ref="appForm" :model="appForm" :rules="rules" label-width="180px" :label-suffix="constants.labelSuffix">
        <ics-page-inner title="绑定银行卡">
          <el-col :span="12">
            <el-form-item prop="bankId" label="银行ID" :rules="{ required: true, message: '请选择银行ID', trigger: 'change' }">
              <el-select v-model="appForm.bankId" placeholder="请选择银行名称/ID" style="width: 100%" filterable>
                <el-option v-for="item in zjBankCodeList" :key="item.code" :label="`${item.name}（${item.code}）`" :value="item.code" />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item prop="bankAccountName" label="银行账户名称" :rules="{ required: true, message: '请输入银行账户名称', trigger: 'blur' }">
              <el-input v-model="appForm.bankAccountName" placeholder="请输入银行账户名称" />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item prop="bankAccountNumber" label="银行账户号码" :rules="{ required: true, message: '请输入银行账户号码', trigger: 'blur' }">
              <el-input v-model="appForm.bankAccountNumber" placeholder="请输入银行账户号码" />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item prop="bankPhoneNumber" label="银行预留手机号" :rules="{ required: true, message: '请输入银行预留手机号', trigger: 'blur' }">
              <el-input v-model="appForm.bankPhoneNumber" placeholder="请输入银行预留手机号" />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item prop="branchName" label="银行联行号" :rules="{ required: true, message: '请输入银行联行号', trigger: 'blur' }">
              <el-input v-model="appForm.branchName" placeholder="请输入银行联行号" />
            </el-form-item>
          </el-col>
          <el-col />
          <el-col :span="24">
            <el-form-item label="银行地址" prop="dsEnterpriseAddress" :rules="{ required: true, message: '请选择银行地址', trigger: 'change' }">
              <div style="width: 55%; display: table;">
                <div style="width: 25%;float:left;margin-right:120px; display: table-cell;">
                  <el-form-item>
                    <el-cascader
                      v-model="appForm.dsEnterpriseAddress"
                      :options="areaList"
                      clearable
                      filterable
                      style="width:370px;"
                      @change="(val)=>changeAddress(val)"
                    />
                  </el-form-item>
                </div>
              </div>
              <div style="width: 736px;float:left;margin-right:10px; display: table-cell;">
                <el-row>
                  <el-col :span="12">
                    <el-form-item>
                      <el-input v-model="appForm.addrDetail" style="display:inline" />
                    </el-form-item>
                  </el-col>
                </el-row>
              </div>
            </el-form-item>
          </el-col>
        </ics-page-inner>
        <ics-page-inner :show-header="true">
          <ics-button-inner :loading="loading.submit" submit-title="提交" cancel-title="返回" :show-cancel="false" @submit="dataProcessing(1)" />
        </ics-page-inner>
      </el-form>
    </div>
  </el-main>
</template>

<script>
import { routeEnterMixin } from '@/assets/js/mixins'
import axios from "axios";
export default {
  components: {},
  mixins: [routeEnterMixin],
  data() {
    return {
      userId: this.$route.query.userId,
      firmId: this.$route.query.firmId,
      appForm: {
        ecBankId: '',
        bankId: '',
        bankAccountName: '',
        bankAccountNumber: '',
        bankPhoneNumber: '',
        branchName: '',
        dsEnterpriseAddress: [],
        province: '',
        city: '',
        district: '',
        provinceCode: '',
        cityCode: '',
        districtCode: '',
        addrDetail: ''
      },
      zjBankCodeList: [],
      accountDetail: {},
      validateFlag: 0,
      areaList: [],
      rules: {},
    }
  },
  created() {
    if (this.userId) {
      this.getDetail()
    }
    this.getZjBankCodeList()
    // 获得省市区地址
    this.getAddress()
  },
  methods: {
    getDetail () {
      this.loading.detail = true
      this.api.system.account.getBankDetail(this.userId).then(result => {
        if (result.data.data.bankInfo) {
          const data = result.data.data.bankInfo
          if (data.provinceCode && data.cityCode && data.districtCode) {
            data.dsEnterpriseAddress = [data.provinceCode, data.cityCode, data.districtCode]
          }
          if (data.bingdingBankMessage) {
            this.$message.warning(data.bingdingBankMessage)
          }
          this.accountDetail = result.data.data || {}
          this.appForm = data || {}
        }
      }).finally(() => {
        this.loading.detail = false
      })
    },
    getZjBankCodeList () {
      this.api.system.account.zjBankCodeList().then(result => {
        this.zjBankCodeList = result.data.data || []
      }).finally(() => {
      })
    },
    /**
     * 获取省市区
     */
    getAddress () {
      axios.get('/capital/v1/mstDictInfo/selectDictInfo/area').then(res => {
        const cityData = JSON.stringify(res.data.data)
        const optionsJson = JSON.parse(cityData.replace(/code/g, 'value').replace(/name/g, 'label'))
        optionsJson.map((item, index) => {
          item.children = item.subList
          if (typeof item.subList !== 'undefined' && item.subList !== null) {
            if (item.subList.length > 0) {
              item.children.map((itemN, indexN) => {
                itemN.children = itemN.subList
              })
            }
          }
        })
        this.areaList = optionsJson
      })
    },
    changeAddress (val) {
      if (val) {
        let code = val || []
        let province = {}
        let city = {}
        let area = {}
        this.areaList.forEach(provinceItem => {
          if (provinceItem.value === code[0]) {
            province = provinceItem
            if (!this._.isEmpty(provinceItem.children)) {
              provinceItem.children.forEach(cityItem => {
                if (cityItem.value === code[1]) {
                  city = cityItem
                  if (!this._.isEmpty(cityItem.children)) {
                    cityItem.children.forEach(areaItem => {
                      if (areaItem.value === code[2]) {
                        area = areaItem
                      }
                    })
                  }
                }
              })
            }
          }
        })
        this.appForm.province = province.label
        this.appForm.city = city.label
        this.appForm.district = area.label
        this.appForm.provinceCode = province.value
        this.appForm.cityCode = city.value
        this.appForm.districtCode = area.value
      } else {
        this.appForm.province = ''
        this.appForm.city = ''
        this.appForm.district = ''
        this.appForm.provinceCode = ''
        this.appForm.cityCode = ''
        this.appForm.districtCode = ''
      }
    },
    dataProcessing (type) {
      if (type === 1) {
        this.submitForms()
      } else {
        if (this.validateFlag === 1) {
          this.$refs.appForm.clearValidate()
        }
        const data = this._.cloneDeep(this.appForm)
        const info = {
          userId: this.userId,
          firmId: this.firmId,
          bankId: data.bankId,
          bankAccountName: data.bankAccountName,
          bankAccountNumber: data.bankAccountNumber,
          bankPhoneNumber: data.bankPhoneNumber,
          branchName: data.branchName,
          province: data.province,
          city: data.city,
          district: data.district,
          provinceCode: data.provinceCode,
          cityCode: data.cityCode,
          districtCode: data.districtCode,
          addrDetail: data.addrDetail
        }
        let api = this.api.system.account.addBank
        if (data.ecBankId) {
          info.ecBankId = data.ecBankId
          api = this.api.system.account.updateBank
        }
        this.loading.submit = true
        api(info).then(result => {
          if (result.data.success === true) {
            this.$message.success('操作成功')
            this.loading.submit = false
            this.getDetail()
          } else {
            this.$message.error(result.data.message)
            this.loading.submit = false
          }
        }).catch(e => {
          this.loading.submit = false
        })
      }
    },
    submitForms () {
      this.$refs.appForm.validate((valid) => {
        if (valid) {
          const data = this._.cloneDeep(this.appForm)
          const info = {
            userId: this.userId,
            firmId: this.firmId,
            bankId: data.bankId,
            bankAccountName: data.bankAccountName,
            bankAccountNumber: data.bankAccountNumber,
            bankPhoneNumber: data.bankPhoneNumber,
            branchName: data.branchName,
            province: data.province,
            city: data.city,
            district: data.district,
            provinceCode: data.provinceCode,
            cityCode: data.cityCode,
            districtCode: data.districtCode,
            addrDetail: data.addrDetail
          }
          data.commitFlag = 1
          let api = this.api.system.account.addBank
          if (data.ecBankId) {
            info.ecBankId = data.ecBankId
            api = this.api.system.account.updateBank
          }
          this.loading.submit = true
          api(info).then(result => {
            if (result.data.success === true) {
              const data = result.data.data
              this.api.system.account.eJz4611(data.userId).then(result => {
                const ejz4611 = JSON.parse(result.data.data)
                console.log(ejz4611)
                if (ejz4611.code === '2000' && ['15', '17', '18'].includes(ejz4611.status)) {
                  this.$message.success(ejz4611.responseMessage ? ejz4611.responseMessage : ejz4611.message)
                  this.$router.push({ name: 'verificationOrPay', query: { userId: data.userId, firmId: data.firmId } })
                } else {
                  this.$message.error(ejz4611.responseMessage ? ejz4611.responseMessage : ejz4611.message)
                  this.loading.submit = false
                }
              })
            } else {
              this.$message.error(result.data.message)
              this.loading.submit = false
            }
          }).catch(e => {
            this.loading.submit = false
          })
        } else {
          this.validateFlag = 1
        }
      })
    }
  }
}
</script>

<style scoped>

</style>
